import { ref, computed, watch } from '@vue/composition-api'
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import moment from 'moment-timezone'
import store from '@/store'
import ability from '@/libs/acl/ability'
import { debounce } from 'lodash'

export default function useList() {
  const refListTable = ref(null)

  const toast = useToast()

  store.dispatch('center/getAll')
  store.dispatch('sector/getAll')
  store.dispatch('type_alarm/getAll')
  store.dispatch('type_device_interphone/getAll')
  store.dispatch('iotcomms_master_account/getAll')
  store.dispatch('user/getAll')
  store.dispatch('unit/getAll')

  const centerOptions = computed(() => store.getters['center/getSelectedItems'])
  const sectorOptions = computed(() => store.getters['sector/getSelectedItems'])
  const typeAlarmOptions = computed(() => store.getters['type_alarm/getSelectedItems'])
  const typeDeviceInterphoneOptions = computed(() => store.getters['type_device_interphone/getSelectedItems'])
  const iotcommsMasterAccountOptions = computed(() => store.getters['iotcomms_master_account/getSelectedItems'])
  const userOptions = computed(() => store.getters['user/getSelectedItems'])
  const unitOptions = computed(() => store.getters['unit/getSelectedItemsWithoutTenant'])

  const centerFilter = ref(null)
  const sectorFilter = ref(null)
  const typeAlarmFilter = ref(null)
  const typeDeviceInterphoneFilter = ref(null)
  const iotcommsMasterAccountFilter = ref(null)
  const userFilter = ref(null)

  const tableColumns = [
    { label: 'device_interphone.device_id', key: 'device_id', sortable: true },
    { label: 'unit.title', key: 'device', sortable: false,
      formatter: (value, key, item) => {
        if(item.device?.units) return [...new Set(item.device.units.map(item => { return item.description}))].join(', ')
          return ""
      }
    },
    { label: 'type_device_interphone.title', key: 'typeDeviceInterphone.type', sortable: true },
    { label: 'iotcomms_master_account.title', key: 'iotcommsMasterAccount.name', sortable: true },
    { label: 'device_interphone.password', key: 'password', sortable: true },
    { label: 'device_interphone.ha1', key: 'ha1', sortable: true },
    { label: 'users.title', key: 'user.email', sortable: true },
    { label: 'centers.title', key: 'center.name', sortable: true },
    { label: 'sectors.title', key: 'sector.name', sortable: true },
    { label: 'device_interphone.ivr', key: 'ivr', sortable: true },
    { label: 'device_interphone.alarm', key: 'alarm', sortable: true },
    { label: 'types_alarms.title', key: 'typeAlarm.description', sortable: true },
    { label: 'date.created_at', key: 'created_at', sortable: true, formatter: 'formatDateAssigned' },
    { label: 'date.updated_at', key: 'updated_at', sortable: true, formatter: 'formatDateAssigned' },
  ]

  if (ability.can('update', 'DeviceInterphone') === true || ability.can('delete', 'DeviceInterphone') === true) {
    tableColumns.splice(0, 0, { label: 'actions.name', key: 'actions' })
  }

  const perPage = ref(10)
  const totalList = ref(0)
  const currentPage = ref(1)
  const perPageOptions = [10, 25, 50, 100]
  const searchQuery = ref('')
  const sortBy = ref('id')
  const isSortDirDesc = ref(true)
  const action = ref('list')

  const dataMeta = computed(() => {
    const localItemsCount = refListTable.value ? refListTable.value.localItems.length : 0
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalList.value,
    }
  })

  const fetchList = (ctx, callback) => {
    store.dispatch('device_interphone/getAll', {
      q: searchQuery.value,
      max: perPage.value,
      'page[n]': currentPage.value,
      sortBy: sortBy.value,
      sortDesc: isSortDirDesc.value,
      action: action.value,
      id_center: centerFilter.value,
      id_sector: sectorFilter.value,
      id_type_alarm: typeAlarmFilter.value,
      id_type_device_interphone: typeDeviceInterphoneFilter.value,
      id_iotcomms_master_account: iotcommsMasterAccountFilter.value,
      id_user: userFilter.value,
    })
      .then(response => {
        if (action.value == 'export') {
          action.value = 'list'
        } else {
          callback(response.data)
          totalList.value = response.meta.total
        }
      })
      .catch(error => {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Error obteniendo la información',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }

  const formatDateAssigned = value => {
    const formattedDate = moment(value).format('DD/MM/YYYY HH:mm:ss')
    return formattedDate
  }

  const refetchData = () => {
    refListTable.value.refresh()
  }

  watch([
    centerFilter,
    sectorFilter,
    typeAlarmFilter,
    typeDeviceInterphoneFilter,
    iotcommsMasterAccountFilter,
    userFilter,
    currentPage,
    perPage,
  ], () => { refetchData() })

  watch([ searchQuery ], debounce(() => {
    refetchData()
  }, 500))

  return {
    tableColumns,
    perPage,
    currentPage,
    totalList,
    fetchList,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refListTable,
    formatDateAssigned,

    refetchData,

    centerOptions,
    sectorOptions,
    typeAlarmOptions,
    typeDeviceInterphoneOptions,
    iotcommsMasterAccountOptions,
    userOptions,
    unitOptions,

    centerFilter,
    sectorFilter,
    typeAlarmFilter,
    typeDeviceInterphoneFilter,
    iotcommsMasterAccountFilter,
    userFilter,

    action,
  }
}

<template>
  <b-card no-body class="m-2">
    <b-card-header class="pb-50 text-capitalize">
      <h5>{{$t('filter')}}</h5>
    </b-card-header>
    <b-card-body>
      <b-row>
        <b-col cols="4" md="4" class="mb-md-0 mb-2 text-capitalize">
          <label>{{$tc('centers.title', 2)}}</label>
          <v-select
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :value="centerFilter"
            :options="centerOptions"
            class="w-100"
            multiple
            :reduce="(val) => val.value"
            @input="(val) => $emit('update:centerFilter', val)"
          />
        </b-col>
        <b-col cols="4" md="4" class="mb-md-0 mb-2 text-capitalize">
          <label>{{$tc('sectors.title', 2)}}</label>
          <v-select
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :value="sectorFilter"
            :options="sectorOptions"
            class="w-100"
            multiple
            :reduce="(val) => val.value"
            @input="(val) => $emit('update:sectorFilter', val)"
          />
        </b-col>
        <b-col cols="4" md="4" class="mb-md-0 mb-2 text-capitalize">
          <label>{{$tc('types_alarms.title', 2)}}</label>
          <v-select
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :value="typeAlarmFilter"
            :options="typeAlarmOptions"
            class="w-100"
            multiple
            :reduce="(val) => val.value"
            @input="(val) => $emit('update:typeAlarmFilter', val)"
          />
        </b-col>
      </b-row>
      <b-row class="my-2">
        <b-col cols="4" md="4" class="mb-md-0 mb-2 text-capitalize">
          <label>{{$tc('type_device_interphone.title', 2)}}</label>
          <v-select
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :value="typeDeviceInterphoneFilter"
            :options="typeDeviceInterphoneOptions"
            class="w-100"
            multiple
            :reduce="(val) => val.value"
            @input="(val) => $emit('update:typeDeviceInterphoneFilter', val)"
          />
        </b-col>
        <b-col cols="4" md="4" class="mb-md-0 mb-2 text-capitalize">
          <label>{{$tc('iotcomms_master_account.title', 2)}}</label>
          <v-select
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :value="iotcommsMasterAccountFilter"
            :options="iotcommsMasterAccountOptions"
            class="w-100"
            multiple
            :reduce="(val) => val.value"
            @input="(val) => $emit('update:iotcommsMasterAccountFilter', val)"
          />
        </b-col>
        <b-col cols="4" md="4" class="mb-md-0 mb-2 text-capitalize">
          <label>{{$tc('users.title', 2)}}</label>
          <v-select
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :value="userFilter"
            :options="userOptions"
            class="w-100"
            multiple
            :reduce="(val) => val.value"
            @input="(val) => $emit('update:userFilter', val)"
          />
        </b-col>
      </b-row>
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard,
  BCardHeader,
  BCardBody,
  BRow,
  BCol,
  BButton,
} from "bootstrap-vue"
import vSelect from "vue-select"

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardHeader,
    BCardBody,
    BButton,
    vSelect,
  },
  props: {
    centerOptions: {
      type: Array,
      default: null,
    },
    centerFilter: {
      type: Array,
      default: null,
    },
    sectorOptions: {
      type: Array,
      default: null,
    },
    sectorFilter: {
      type: Array,
      default: null,
    },
    typeAlarmOptions: {
      type: Array,
      default: null,
    },
    typeAlarmFilter: {
      type: Array,
      default: null,
    },
    typeDeviceInterphoneOptions: {
      type: Array,
      default: null,
    },
    typeDeviceInterphoneFilter: {
      type: Array,
      default: null,
    },
    iotcommsMasterAccountOptions: {
      type: Array,
      default: null,
    },
    iotcommsMasterAccountFilter: {
      type: Array,
      default: null,
    },
    userOptions: {
      type: Array,
      default: null,
    },
    userFilter: {
      type: Array,
      default: null,
    },
  },
};
</script>